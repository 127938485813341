import React from "react";
import Modal from "react-bootstrap/Modal";

// styles
import * as styles from "./VideoCard.module.css";
//constants
// const videoLink =
//   "https://player.vimeo.com/video/489364762?autoplay=1&loop=0&autopause=0&muted=0";

const VideoModal = props => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        contentClassName={styles.newContentVideoModal}
      >
        <div className={styles.newVideoModalClose}>
          <span
            className="icon-cross"
            aria-hidden="true"
            role="presentation"
            onClick={props.onHide}
          ></span>
        </div>
        <iframe
          title={"feedback"}
          src={props.videoLink}
          frameborder="0"
          allow="autoplay; fullscreen"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
    </>
  );
};

export default VideoModal;
