import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import Loader from "react-loader-spinner";

// seo
import Seo from "../components/Seo";

// config
import config from "../config";

// constant
import constant from "../constants";

// utils
import localStorage from "../utils/LocalStorageService";

// components
import Header from "../components/CsvUpload/Header/Header";
import SensorToggle from "../components/CsvUpload/LearnSection/SensorToggle/SensorToggle";
import VideoCard from "../components/CsvUpload/LearnSection/VideoCard/VideoCard";
import UploadFile from "../components/CsvUpload/FileUpload/FileUpload";
import ToggleButton from "../components/CsvUpload/ToggleButton/ToggleButton";
import SuccessToast from "../components/Toasts/SuccessToast";

// css
import * as styles from "../components/CsvUpload/CsvUpload.module.css";

const CsvUploadPage = () => {
  const [toggleButtonValue, setToggleButtonValue] = useState("default"); // show learn sec and upload sec for mobile view based on toggle button
  const [sensorButtonValue, setSensorButtonValue] = useState("libre"); // Sensor toggle button values ie libre or libre-pro
  const [clientData, setClientData] = useState({}); // client details
  const [ctaDisabled, setCtaDisabled] = useState(true); // To enable/disable upload cta
  const [fileToUpload, setFileToUpload] = useState({}); // csv file to be uploaded
  const [windowVideoLink, setWindowVideoLink] = useState(
    constant.CSV_UPLOAD_VIDEOS.libreWindowsLink
  ); // video link for windows os
  const [macVideoLink, setMacVideoLink] = useState(
    constant.CSV_UPLOAD_VIDEOS.libreMacLink
  ); // video link for mac os
  const [showSuccessToast, setShowSuccessToast] = useState(false); // On success upload of cgm files
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  // Function ->> To get access token from local storage and call client info api to get client details
  useEffect(() => {
    const accessToken = localStorage.getAccessToken();

    // Function ->> To get the client details
    const getClientInfo = async token => {
      try {
        const clientInfoOptions = {
          method: "get",
          url: config.ICAP_PLATFORM_URL + "/api/client/info",
          headers: {
            "X-Authorization": `Bearer ${token}`,
          },
        };

        const clientInfoResponse = await axios(clientInfoOptions);
        setClientData(clientInfoResponse.data);

        // Handle members based on their status ie registration, inactive and discharged
        if (clientInfoResponse.data.status === "REGISTRATION") {
          // member unable to load the csv files
          setServerError(
            "Upload disabled as the user has not yet been activated"
          );
        } else if (
          clientInfoResponse.data.status === "INACTIVE" ||
          clientInfoResponse.data.status === "DISCHARGED"
        ) {
          // if a member is inactive or discharged, logout the member
          handleLogout();
        } else {
          setServerError("");
        }
      } catch (error) {
        console.log("Error in icap client info api:", error);
        // setServerError(error.response.data.message);
        if (
          error.response.status === 401 &&
          error.response.data.code === "ACCESS_TOKEN_EXPIRED"
        ) {
          refreshAccessTokenMember();
          return;
        } else if (
          error.response.status === 401 &&
          (error.response.data.code === "INVALID_REFRESH_TOKEN" ||
            error.response.data.code === "INVALID_ACCESS_TOKEN" ||
            error.response.data.code === "REFRESH_TOKEN_EXPIRED" ||
            error.response.data.code === "PASSWORD_CHANGED" ||
            error.response.data.code === "UUID_NOT_MATCH" ||
            error.response.data.code === "USER_ID_NOT_MATCH")
        ) {
          handleLogout();
          return;
        } else {
          setServerError(error.response.data.message);
        }
      }
    };

    // If access token is not present in local storage
    // navigate the user to csv upload login page
    if (!accessToken) {
      navigate("/csv-login");
    } else {
      getClientInfo(accessToken);
    }
  }, []);

  // Function ->> Restrict(logout) user to move back and forth when user clicks the browser back button
  useEffect(() => {
    window.addEventListener("popstate", handleLogout);

    return () => {
      window.removeEventListener("popstate", handleLogout);
    };
  }, []);

  // Function ->> To Logout The User
  const handleLogout = () => {
    localStorage.clearToken();
    navigate("/csv-login"); // redirect to csv login page
  };

  // Function ->> To refresh access token if the access token got expired
  const refreshAccessTokenMember = async () => {
    const accessTokenExpiryTime = localStorage.getAccessTokenExpiryTime();

    if (new Date(accessTokenExpiryTime) < Date.now()) {
      const deviceId = localStorage.getValue("DeviceId");
      const oldAccessToken = localStorage.getAccessToken();
      const oldRefreshToken = localStorage.getRefreshToken();

      try {
        const newTokenOptions = {
          method: "post",
          url: config.ICAP_PLATFORM_URL + "/api/jwt/refresh",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            deviceUUID: deviceId.id.toString(),
            oldAccessToken: oldAccessToken,
            oldRefreshToken: oldRefreshToken,
          },
        };

        const newTokenResponse = await axios(newTokenOptions);
        localStorage.setToken(newTokenResponse.data);
        setServerError("");
      } catch (error) {
        console.error("Error in refresh access token api:", error);
        setServerError(error.response.data.message);
        if (
          error.response.status === 401 &&
          (error.response.data.code === "INVALID_REFRESH_TOKEN" ||
            error.response.data.code === "INVALID_ACCESS_TOKEN" ||
            error.response.data.code === "REFRESH_TOKEN_EXPIRED" ||
            error.response.data.code === "PASSWORD_CHANGED" ||
            error.response.data.code === "UUID_NOT_MATCH" ||
            error.response.data.code === "USER_ID_NOT_MATCH")
        ) {
          handleLogout();
          return;
        }
      }
    }
  };

  // Function ->> To upload the csv file from local system
  const handleUploadFile = async (val, val1) => {
    if (val) {
      let formData = new FormData();
      const fileName = `${clientData.id}-${clientData.firstName}-${val.name}`;
      formData.append("file", val, fileName);
      formData.append("clientId", Number(clientData.id));

      setFileToUpload(formData);
      setCtaDisabled(false);

      // Disabling submit button on removing/deleting the file
      if (val1) {
        setCtaDisabled(true);
        setServerError("");
      }
    }
  };

  // Function ->> handle error - when user tries to upload file more than defined size
  const handleUploadFileError = val => {
    if (val) setServerError(val);
  };

  // Function ->> Upload api calls on clicking of upload button
  const handleSubmitFile = async () => {
    const accessToken = localStorage.getAccessToken();

    // api to check file format is correct or not
    const uploadOptions = {
      method: "post",
      url: config.ICAP_PLATFORM_URL + "/api/clinic/glucoseValues/cgm/new",
      headers: {
        "content-type": "multipart/form-data",
        "X-Authorization": `Bearer ${accessToken}`,
      },
      data: fileToUpload,
    };

    // api to upload cgm files finally to the platform/indiaclinic dashboard
    const cgmUploadOptions = {
      method: "post",
      url: config.ICAP_PLATFORM_URL + "/api/clinic/glucoseValues/uploadAbbott",
      headers: {
        "content-type": "multipart/form-data",
        "X-Authorization": `Bearer ${accessToken}`,
      },
      data: fileToUpload,
    };

    try {
      setServerError("");
      setLoading(true);
      // const response = await axios(uploadOptions);

      await axios(uploadOptions);
      await axios(cgmUploadOptions);
      setShowSuccessToast(true);
      setCtaDisabled(true);

      // if (response.data === true) {
      //   await axios(cgmUploadOptions);
      //   setShowSuccessToast(true);
      //   setCtaDisabled(true);
      // } else {
      //   setServerError(
      //     "This file already exists in our system. Please update the file"
      //   );
      // }
      setLoading(false);
    } catch (error) {
      setLoading(true);
      console.log("errorr in uploading file", error);
      if (
        error.response.status === 401 &&
        error.response.data.code === "ACCESS_TOKEN_EXPIRED"
      ) {
        handleLogout();
        return;
      } else if (
        error.response.status === 401 &&
        (error.response.data.code === "INVALID_REFRESH_TOKEN" ||
          error.response.data.code === "INVALID_ACCESS_TOKEN" ||
          error.response.data.code === "REFRESH_TOKEN_EXPIRED" ||
          error.response.data.code === "PASSWORD_CHANGED" ||
          error.response.data.code === "UUID_NOT_MATCH" ||
          error.response.data.code === "USER_ID_NOT_MATCH")
      ) {
        handleLogout();
        return;
      } else {
        setServerError(error.response.data.message);
      }
      setLoading(false);
    }
  };

  // Function ->> This function is to handle view for Referral form & Learn Sec
  // for mobile view screen and called in "ToggleButton Component"
  const handleToggleButton = value => {
    setToggleButtonValue(value);
  };

  // Function ->> To Handle Video links for Different Cgm Sensors(i.e Libre and Libre-Pro)
  const handleSensorToggleButton = val => {
    setSensorButtonValue(val);
    if (val === "libre_pro") {
      setWindowVideoLink(constant.CSV_UPLOAD_VIDEOS.libreProWindowsLink);
      setMacVideoLink(constant.CSV_UPLOAD_VIDEOS.libreProMacLink);
    } else {
      setWindowVideoLink(constant.CSV_UPLOAD_VIDEOS.libreWindowsLink);
      setMacVideoLink(constant.CSV_UPLOAD_VIDEOS.libreMacLink);
    }
  };

  return (
    <div className="bgWhiteColor">
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />
      {/* Header Component */}
      <Header
        handleLogout={handleLogout}
        zendeskUrl={`https://twinhealth-in-cgmupload.zendesk.com/hc/en-us/requests/new?ticket_form_id=6210198542353&tf_6210162501649=sensors_continuous_glucose_monitor_cgm_other_in&tf_15406100869137=sensors_continuous_glucose_monitor_cgm_cant_upload_cgm_file_in&tf_subject=Issue+with+CGM+file+uploading&tf_anonymous_requester_email=marsyfranco@gmail.com&tf_6916245054097=${clientData.id}&tf_7619283460241=${clientData.login}`}
      />

      <div className={styles.csvUploadPge__mobToggleBtn}>
        <ToggleButton
          handleToggleButton={handleToggleButton}
          handleLogout={handleLogout}
        />
      </div>

      {toggleButtonValue === "default" ? (
        // FOR DESKTOP VIEW ONLY
        <div className={`container ${styles.csvUploadPge}`}>
          <div className="row">
            {/* Making pointer events none for uploading files for Registration status members */}
            <div
              className={`col-sm-12 col-xs-12 col-md-8 col-lg-8 ${
                clientData.status === "REGISTRATION"
                  ? styles.csvUploadPge__regUpload
                  : styles.csvUploadPge__hdn2
              }`}
            >
              <h1>Upload</h1>
              <UploadFile
                handleUploadFile={handleUploadFile}
                handleUploadFileError={handleUploadFileError}
              />
              {/* CTA */}
              <div
                className={
                  ctaDisabled
                    ? styles.fileUploadSec__ctaDis
                    : styles.fileUploadSec__cta
                }
              >
                <button
                  type="submit"
                  onClick={handleSubmitFile}
                  disabled={ctaDisabled}
                >
                  <span className={loading && styles.csvUploadPage__cta__txt}>
                    Upload
                  </span>
                  <span className={styles.csvUploadPage__cta__loader}>
                    <Loader
                      type="TailSpin"
                      color="#FFF"
                      height={30}
                      width={30}
                      radius={1}
                      visible={loading}
                    />
                  </span>
                </button>
              </div>
              <div className="text-danger text-center mt-3">{serverError}</div>
            </div>
            <div
              className={`col-sm-12 col-xs-12 col-md-4 col-lg-4 ${styles.csvUploadPge__hdn1}`}
            >
              <h1>Get started</h1>
              <SensorToggle
                handleSensorToggleButton={handleSensorToggleButton}
              />
              <div className={styles.csvUploadPge__mobLearnSec__pTag}>
                {sensorButtonValue === "libre" ? (
                  <p>We support MacOS and Windows only</p>
                ) : (
                  <p>Only Windows is supported with Libre Pro patches</p>
                )}
              </div>
              <VideoCard
                windowVideoLink={windowVideoLink}
                macWindowLink={macVideoLink}
                sensorButtonValue={sensorButtonValue}
                libreWindowDownloadLink={
                  constant.CSV_UPLOAD_DOWNLOAD_LINKS.libreWindowsLink
                }
                libreMacDownloadlink={
                  constant.CSV_UPLOAD_DOWNLOAD_LINKS.libreMacLink
                }
                libreProWindowDownloadLink={
                  constant.CSV_UPLOAD_DOWNLOAD_LINKS.libreProWindowsLink
                }
              />
            </div>
          </div>
        </div>
      ) : (
        // FOR MOBILE VIEW ONLY
        <div>
          {toggleButtonValue === "upload" ? (
            <div
              className={
                clientData.status === "REGISTRATION"
                  ? styles.csvUploadPge__regMobUploadSec
                  : styles.csvUploadPge__mobUploadSec
              }
            >
              <UploadFile
                handleUploadFile={handleUploadFile}
                handleUploadFileError={handleUploadFileError}
              />
              {/* CTA */}
              <div
                className={
                  ctaDisabled
                    ? styles.fileUploadSec__ctaDis
                    : styles.fileUploadSec__cta
                }
              >
                <button
                  type="submit"
                  onClick={handleSubmitFile}
                  disabled={ctaDisabled}
                >
                  <span className={loading && styles.csvUploadPage__cta__txt}>
                    Upload
                  </span>
                  <span className={styles.csvUploadPage__cta__loader}>
                    <Loader
                      type="TailSpin"
                      color="#FFF"
                      height={30}
                      width={30}
                      radius={1}
                      visible={loading}
                    />
                  </span>
                </button>
              </div>
              <div className="text-center text-danger mt-3">{serverError}</div>
            </div>
          ) : (
            <div className={styles.csvUploadPge__mobLearnSec}>
              <SensorToggle
                handleSensorToggleButton={handleSensorToggleButton}
              />
              <div className={styles.csvUploadPge__mobLearnSec__pTag}>
                {sensorButtonValue === "libre" ? (
                  <p>We support MacOS and Windows only</p>
                ) : (
                  <p>Only Windows is supported with Libre Pro patches</p>
                )}
              </div>
              <VideoCard
                windowVideoLink={windowVideoLink}
                macWindowLink={macVideoLink}
                sensorButtonValue={sensorButtonValue}
                libreWindowDownloadLink={
                  constant.CSV_UPLOAD_DOWNLOAD_LINKS.libreWindowsLink
                }
                libreMacDownloadlink={
                  constant.CSV_UPLOAD_DOWNLOAD_LINKS.libreMacLink
                }
                libreProWindowDownloadLink={
                  constant.CSV_UPLOAD_DOWNLOAD_LINKS.libreProWindowsLink
                }
              />
            </div>
          )}
        </div>
      )}

      {/* Toast components */}
      <SuccessToast
        position="top-center"
        showSuccessToast={showSuccessToast}
        delay={5000}
        autohide={true}
        text="CGM data uploaded successfully."
        onClose={() => setShowSuccessToast(false)}
      />
    </div>
  );
};

export default CsvUploadPage;
