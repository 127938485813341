// extracted by mini-css-extract-plugin
export var fileRemove__div = "FileUpload-module--fileRemove__div--OZp8d";
export var fileUploadSec_cntDiv = "FileUpload-module--fileUploadSec_cntDiv--Kcnfa";
export var fileUploadSec_fileSec = "FileUpload-module--fileUploadSec_fileSec--l1uCX";
export var fileUploadSec_fileSec_icn = "FileUpload-module--fileUploadSec_fileSec_icn--6fkij";
export var fileUploadSec_fileSec_icnTxt = "FileUpload-module--fileUploadSec_fileSec_icnTxt--ml-cB";
export var fileUploadSec_fileSec_uploadedFile = "FileUpload-module--fileUploadSec_fileSec_uploadedFile--wTAWv";
export var fileUploadSec_hdng = "FileUpload-module--fileUploadSec_hdng--3-74w";
export var fileUploadSec_img = "FileUpload-module--fileUploadSec_img--gQtoP";
export var fileUploadSec_subHdng = "FileUpload-module--fileUploadSec_subHdng--QJGbo";
export var fileUploadSec_uploadedImg = "FileUpload-module--fileUploadSec_uploadedImg--da1Ez";
export var fileUploadSec_uploadedImg_txt = "FileUpload-module--fileUploadSec_uploadedImg_txt--XWlad";