import React, { useState } from "react";

// css
import * as styles from "./ToggleButton.module.css";

const ToggleButton = props => {
  const [toggleState, setToggleState] = useState("upload");

  const updateSensorToggleButtonValue = e => {
    props.handleToggleButton(e.target.value);
    setToggleState(e.target.value);
  };

  return (
    <div>
      <div>
        <div className={`d-flex align-items-center`}>
        <button
            onClick={updateSensorToggleButtonValue}
            value="upload"
            className={
              toggleState && toggleState === "upload"
                ? styles.toggleBtn__selected
                : styles.toggleBtn__unSelected
            }
          >
            Upload
          </button>
          <button
            onClick={updateSensorToggleButtonValue}
            value="learn"
            className={
              toggleState && toggleState === "learn"
                ? styles.toggleBtn__selected
                : styles.toggleBtn__unSelected
            }
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
