import React, { Component } from "react";
import Files from "react-files";
import { StaticImage } from "gatsby-plugin-image";

// css
import * as styles from "./FileUpload.module.css";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      serverError: "",
    };
  }

  onFilesChange = file => {
    this.setState({ files: file });

    // when user upload a correct file size
    if (file.length !== 0) {
      const fileRemoved = false;
      this.setState({ serverError: "" });
      this.props.handleUploadFile(file[0], fileRemoved);
    } else {
      this.setState({
        serverError:
          "The file is too large to upload. The max supported file size is 20MB",
      });
    }

    // callback function -> to handle Error when user tried to upload file more than defined file size
    setTimeout(() => {
      this.props.handleUploadFileError(this.state.serverError);
    }, 500);
  };

  filesRemoveOne = file => {
    this.setState({
      // files: this.state.files.filter(item => item.id !== file.id),
      files: [],
    });
    const fileRemoved = true;
    this.props.handleUploadFile(file, fileRemoved);
  };

  render() {
    const { files } = this.state;

    return (
      <div>
        <div>
          {/* Heading */}
          <div className={styles.fileUploadSec_hdng}>
            <h3>Upload CSV or Txt file from sensor</h3>
          </div>
          <div className="d-block">
            {/* Upload file */}
            <div className={styles.fileUploadSec_fileSec}>
              <Files
                className="files-dropzone"
                onChange={this.onFilesChange}
                accepts={[".csv", ".txt"]}
                multiple={false}
                // maxFileSize={50000000}
                minFileSize={0}
                clickable
              >
                {/* upload icon */}
                <div className={styles.fileUploadSec_fileSec_icn}>
                  <StaticImage
                    src="../../../images/csvUpload/upload_icon.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    width={40}
                  />
                </div>
                {/* text */}
                <div className={styles.fileUploadSec_fileSec_icnTxt}>
                  <h3>Choose a CSV or Txt file</h3>
                  <p>Or drag and drop</p>
                </div>
              </Files>
            </div>
            {/* Preview of image */}
            {files.length > 0 ? (
              <div className={`${styles.fileUploadSec_fileSec_uploadedFile}`}>
                <ul>
                  {files.map(file => (
                    <li className="files-list-item" key={file.id}>
                      <div
                        className="files-list-item-preview"
                        style={{ width: "100%" }}
                      >
                        {file.preview.type === "image" ? (
                          <img
                            className={`files-list-item-preview-image ${styles.fileUploadSec_img}`}
                            src={file.preview.url}
                            alt=""
                          />
                        ) : (
                          <div
                            className={`files-list-item-preview-extension d-flex ${styles.fileUploadSec_cntDiv}`}
                          >
                            <div className={styles.fileUploadSec_uploadedImg}>
                              <StaticImage
                                src="../../../images/csvUpload/file_icon.png"
                                alt=""
                                className="img-fluid"
                                placeholder="blurred"
                                quality={90}
                                style={{ width: "32px" }}
                              />
                            </div>
                            <div
                              className={styles.fileUploadSec_uploadedImg_txt}
                            >
                              <h3>{file.name}</h3>
                              <p>{file.sizeReadable}</p>
                            </div>
                            <div
                              id={file.id}
                              className={`files-list-item-remove ${styles.fileRemove__div}`}
                              onClick={this.filesRemoveOne.bind(this, file)} // eslint-disable-line
                              role="presentation"
                            >
                              <StaticImage
                                src="../../../images/csvUpload/trash_icon.png"
                                alt=""
                                className="img-fluid"
                                placeholder="blurred"
                                quality={90}
                                style={{ width: "20px" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
