import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// css
import * as styles from "./Header.module.css";

const Header = props => {
  return (
    <>
      <div
        className={`container d-flex align-items-center ${styles.headerSec}`}
      >
        {/* Logo */}
        <div className={styles.headerSec__logoImg} role="presentation">
          <StaticImage
            src="../../../images/twin-logo/twinLogo1_2024.png"
            alt=""
            className="img-fluid"
            placeholder="blurred"
            quality={90}
            height={95}
          />
        </div>
        {/* Buttons */}
        <div
          className={`d-flex align-items-center ${styles.headerSec__buttons}`}
        >
          <Link to={props.zendeskUrl}>
            <div
              className={`d-flex align-items-center ${styles.headerSec__supportCta}`}
              aria-hidden="true"
            >
              <StaticImage
                src="../../../images/csvUpload/help_center_logo.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
                width={24}
              />
              <p>Help Center</p>
            </div>
          </Link>
          <div
            className={`d-flex align-items-center ${styles.headerSec__logoutCta}`}
            onClick={props.handleLogout}
            aria-hidden="true"
          >
            <StaticImage
              src="../../../images/csvUpload/signout_logo.png"
              alt=""
              className="img-fluid"
              placeholder="blurred"
              quality={90}
              width={24}
            />
            <p>Sign out</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
