// extracted by mini-css-extract-plugin
export var csvLoginPage = "CsvUpload-module--csvLoginPage--3azKv";
export var csvLoginPage__bannerImage = "CsvUpload-module--csvLoginPage__bannerImage--IJsyO";
export var csvUploadPage__cta__loader = "CsvUpload-module--csvUploadPage__cta__loader--2SsT5";
export var csvUploadPage__cta__txt = "CsvUpload-module--csvUploadPage__cta__txt--PJo5T";
export var csvUploadPge = "CsvUpload-module--csvUploadPge--lyggQ";
export var csvUploadPge__hdn1 = "CsvUpload-module--csvUploadPge__hdn1--BhLXI";
export var csvUploadPge__hdn2 = "CsvUpload-module--csvUploadPge__hdn2--0d2lp";
export var csvUploadPge__mobLearnSec = "CsvUpload-module--csvUploadPge__mobLearnSec--pZxRR";
export var csvUploadPge__mobLearnSec__pTag = "CsvUpload-module--csvUploadPge__mobLearnSec__pTag--uK14l";
export var csvUploadPge__mobToggleBtn = "CsvUpload-module--csvUploadPge__mobToggleBtn--9EVve";
export var csvUploadPge__mobUploadSec = "CsvUpload-module--csvUploadPge__mobUploadSec--D71Qn";
export var csvUploadPge__regMobUploadSec = "CsvUpload-module--csvUploadPge__regMobUploadSec--gB7qf";
export var csvUploadPge__regUpload = "CsvUpload-module--csvUploadPge__regUpload--sr0Sv";
export var fileUploadSec__cta = "CsvUpload-module--fileUploadSec__cta--0gYI-";
export var fileUploadSec__ctaDis = "CsvUpload-module--fileUploadSec__ctaDis--KZE5X";