import React, { useState } from "react";

// css
import * as styles from "./SensorToggle.module.css";

const SensorToggle = (props) => {
  const [toggleState, setToggleState] = useState("libre");

  const updateSensorToggleButtonValue = e => {
    setToggleState(e.target.value);
    props.handleSensorToggleButton(e.target.value);
  };

  return (
    <div>
      <div className={styles.sensorToggleHdng}>
        <p>Which CGM do you use?</p>
      </div>
      <div>
        <div className={`d-flex align-items-center`}>
          <button
            onClick={updateSensorToggleButtonValue}
            value="libre"
            className={
              toggleState && toggleState === "libre"
                ? styles.toggleBtn__selected
                : styles.toggleBtn__unSelected
            }
          >
            Libre
          </button>
          <button
            onClick={updateSensorToggleButtonValue}
            value="libre_pro"
            className={
              toggleState && toggleState === "libre_pro"
                ? styles.toggleBtn__selected
                : styles.toggleBtn__unSelected
            }
          >
            Libre Pro
          </button>
        </div>
      </div>
    </div>
  );
};

export default SensorToggle;
