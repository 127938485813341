import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// component
import VideoModal from "./VideoModal";

// css
import * as styles from "./VideoCard.module.css";

const VideoCard = props => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectOs, setSelectOs] = useState("");

  const toggleVideo = val => {
    setShowVideoModal(!showVideoModal);
    setSelectOs(val);
  };
  return (
    <div>
      <div className={styles.videoCardSec}>
        <div className={styles.videoCardSec__card}>
          <div>
            <div className="d-flex align-items-center">
              <StaticImage
                src="../../../../images/csvUpload/windows_logo.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
                width={15}
              />
              <h3 className={styles.videoCardSec__card__hdng}>
                Get started on Windows
              </h3>
            </div>
            <p className={styles.videoCardSec__card__para}>
              Jump right in and get an overview of the basics.
            </p>
            <div
              className={`d-flex align-items-center justify-content-between`}
            >
              <div
                className={`d-flex align-items-center ${styles.videoCardSec__card__videoPara}`}
                role="presentation"
                onClick={() => {
                  toggleVideo("window");
                }}
              >
                <StaticImage
                  src="../../../../images/csvUpload/play_icon.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                  width={15}
                />
                <p>Watch video</p>
              </div>
              <Link
                to={
                  props.sensorButtonValue === "libre"
                    ? props.libreWindowDownloadLink
                    : props.libreProWindowDownloadLink
                }
                target="_blank"
                className="text-decoration-none"
              >
                <div
                  className={`d-flex align-items-center ${styles.videoCardSec__card__downloadPara}`}
                  role="presentation"
                >
                  <StaticImage
                    src="../../../../images/csvUpload/download_icon.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    width={15}
                  />
                  <p>Download software</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {props.sensorButtonValue === "libre" ? (
        <div className={styles.videoCardSec}>
          <div className={styles.videoCardSec__card}>
            <div>
              <div className="d-flex align-items-center">
                <StaticImage
                  src="../../../../images/csvUpload/mac_logo.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                  width={15}
                />
                <h3 className={styles.videoCardSec__card__hdng}>
                  Get started on Mac
                </h3>
              </div>
              <p className={styles.videoCardSec__card__para}>
                Jump right in and get an overview of the basics.
              </p>
              <div
                className={`d-flex align-items-center justify-content-between`}
              >
                <div
                  className={`d-flex align-items-center ${styles.videoCardSec__card__videoPara}`}
                  role="presentation"
                  onClick={() => {
                    toggleVideo("mac");
                  }}
                >
                  <StaticImage
                    src="../../../../images/csvUpload/play_icon.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    width={15}
                  />
                  <p>Watch video</p>
                </div>
                <Link
                  to={props.libreMacDownloadlink}
                  target="_blank"
                  className="text-decoration-none"
                >
                  <div
                    className={`d-flex align-items-center ${styles.videoCardSec__card__downloadPara}`}
                    role="presentation"
                  >
                    <StaticImage
                      src="../../../../images/csvUpload/download_icon.png"
                      alt=""
                      className="img-fluid"
                      placeholder="blurred"
                      quality={90}
                      width={15}
                    />
                    <p>Download software</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Video Modal */}
      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={
          selectOs === "window" ? props.windowVideoLink : props.macWindowLink
        }
      />
    </div>
  );
};

export default VideoCard;
